@font-face {
    font-family: 'Brandon-Grotesque-Regular';
    src: url('/static/fonts/Brandon-Grotesque-Regular.woff2') format('woff2'),
    url('/static/fonts/Brandon-Grotesque-Regular.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon-Grotesque-Bold';
    src: url('/static/fonts/Brandon-Grotesque-Bold.woff2') format('woff2'),
    url('/static/fonts/Brandon-Grotesque-Bold.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'Amorie-Modella';
    src: url('/static/fonts/Amorie-Modella-small.woff2') format('woff2'),
    url('/static/fonts/Amorie-Modella-small.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}