:root {
    --color-primary: #0B7746 ;
    --color-secondary-kognitiv: #b7cc7b;
    --color-secondary: #4C4C4A;
    --color-bg: #FAF4EA;
    --color-bg-dark: #E6E0D8;
    --color-text-default: #1F1F1D;
    --color-text-muted: #999999;


    --color-uab: #0B7746 ;
    --color-pzg: #4ab7cc ;


    --color-default: #1F1F1D;
    --color-white: #FFF;
    --color-grey: #d5cfc7;
    --color-light-grey: #FAF4EA;
    --color-dark-grey: #4C4C4A;
    --color-dark: #1F1F1D;

    --color-success: #0B7746;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #07603e;
    --color-secondary-dark: #373735;
    --color-success-dark: #1f7b37;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #0b8f59;
    --color-secondary-light: #767674;
    --color-success-light: #08ad31;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "Brandon-Grotesque-Regular", sans-serif;
    --font-default-bold: "Brandon-Grotesque-Bold", sans-serif;
    --font-default-medium: "Brandon-Grotesque-Regular", sans-serif;
    --font-amorie: "Amorie-Modella", sans-serif;


    --font-size-default: calc(16rem/16);
}
